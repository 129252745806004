import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class WarehouseService extends BaseService {
  getWarehouses({ q, start, limit, filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setPaging({ start, limit })
      .setFilters(filters)
      .build()
    return this.client.get(`warehouses?${searchParams}`, { signal }).json()
  }

  getWarehouse(id) {
    return this.client.get(`warehouses/${id}`).json()
  }

  createWarehouse(newWarehouse) {
    return this.client.post('warehouses', { json: newWarehouse }).json()
  }

  updateWarehouse(warehouseId, updatedWarehouse) {
    return this.client
      .put(`warehouses/${warehouseId}`, { json: updatedWarehouse })
      .json()
  }

  updateWarehouseStatus(warehouseId, status) {
    const statuses = ['ONHOLD', 'ACTIVE', 'ARCHIVE', 'REJECT']
    if (!statuses.includes(status)) {
      throw new Error('Invalid status')
    }
    return this.client.put(`warehouses/${warehouseId}/status?status=${status}`).json()
  }

  createPoolParameters(warehouseId, poolParameters) {
    return this.client
      .post(`warehouses/${warehouseId}/pool-parameters`, { json: poolParameters })
      .json()
  }

  updatePoolParameters(warehouseId, parameterId, poolParameters) {
    return this.client
      .put(`warehouses/${warehouseId}/pool-parameters/${parameterId}`, {
        json: poolParameters,
      })
      .json()
  }

  deletePoolParameter(warehouseId, parameterId) {
    return this.client
      .delete(`warehouses/${warehouseId}/pool-parameters/${parameterId}`)
      .json()
  }

  createEligibilityCriteria(warehouseId, eligibilityCriteria) {
    return this.client
      .post(`warehouses/${warehouseId}/eligibility-criteria`, {
        json: eligibilityCriteria,
      })
      .json()
  }

  updateEligibilityCriteria(warehouseId, parameterId, eligibilityCriteria) {
    return this.client
      .put(`warehouses/${warehouseId}/eligibility-criteria/${parameterId}`, {
        json: eligibilityCriteria,
      })
      .json()
  }

  deleteEligibilityCriteria(warehouseId, parameterId) {
    return this.client
      .delete(`warehouses/${warehouseId}/eligibility-criteria/${parameterId}`)
      .json()
  }

  readPoolParameters(warehouseId) {
    return this.client.get(`warehouses/${warehouseId}/pool-parameters`).json()
  }

  setDefaultWarehouse(warehouseId, status) {
    return this.client
      .put(`warehouses/${warehouseId}/set-default?status=${status}`)
      .json()
  }

  updateFunderDrawDowns(warehouseId, drawDowns) {
    return this.client
      .put(`warehouses/${warehouseId}/funder-draw-downs`, {
        json: drawDowns,
      })
      .json()
  }

  validatePoolParameters(id, loanIds) {
    return this.client
      .post(`warehouses/${id}/pool-parameters/result`, { json: { loanIds } })
      .json()
  }

  undoPendingState(id) {
    return this.client
      .delete(`warehouses/${id}/pending-state`)
      .json()
  }
}
