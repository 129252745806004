import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMyProfile,
  setUserCurrentRole,
  selectIsFetchMyProfileLoading,
  selectIsSessionValid,
  selectProfileRoles,
} from '@vega/redux.profile'
import { APPS } from '@vega/constants'
import { redirectUnauthenticatedUserToLogIn } from './redirectUnauthenticatedUserToLogIn'
import { getUserCurrentRole } from './getUserCurrentRole'
import { Loading, ErrorPage } from '@vega/components'

const isDev = process.env.NODE_ENV === 'development'

const isUnauthorizedUser = (userCurrentRole) => isNilOrEmpty(userCurrentRole)

const AccessController = ({ children, targetAppName, permittedRoles = [] }) => {
  const isFetchingMyProfile = useSelector(selectIsFetchMyProfileLoading)
  const isAuthenticatedUser = useSelector(selectIsSessionValid)
  // User can have multiple roles, and we select the role with most privilege as their current role
  const userRoles = useSelector(selectProfileRoles) ?? []
  const userCurrentRole = getUserCurrentRole(permittedRoles, userRoles)

  const dispatch = useDispatch()

  useEffect(() => {
    // Mark down which app the user wants to navigate to
    // Redirect them after they have logged in
    if (!targetAppName) throw new Error('Please set target application')

    Cookies.set('appInfo', JSON.stringify({ name: targetAppName }), {
      domain: process.env.REACT_APP_VEGA_SUBDOMAIN,
      secure: !isDev,
      sameSite: 'strict',
    })
  }, [targetAppName])

  useEffect(() => {
    dispatch(fetchMyProfile())
  }, [dispatch])

  useEffect(() => {
    if (userCurrentRole) {
      // Storing in local storage for Http Client to access and set it as request header
      window.localStorage.setItem('vegaUserCurrentRole', userCurrentRole)
      dispatch(setUserCurrentRole(userCurrentRole))
    }
  }, [dispatch, userCurrentRole])

  if (isFetchingMyProfile) return <Loading />

  if (!isAuthenticatedUser) redirectUnauthenticatedUserToLogIn(APPS.LOAN_ORIGINATION)

  if (isUnauthorizedUser(userCurrentRole)) return <ErrorPage />

  return children
}

export { AccessController }
